<template>
  <div class="work">
    <!-- 购买流程 -->
    <div class="mytipul">
      <div class="mytipulBox">
        <span>
          <svg viewBox="0 0 16 16"
               class="bi bi-wallet-fill"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
            <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
          </svg>
        </span>
        <p>产品付款发布</p>
      </div>
      <div class="mytipulImg">
        <img src="../../assets/img/liuc.png"
             alt="">
      </div>
      <div class="mytipulBox">
        <span>
          <svg viewBox="0 0 16 16"
               class="bi bi-wallet-fill"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
            <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
          </svg>
        </span>
        <p>产品付款发布</p>
      </div>
      <div class="mytipulImg">
        <img src="../../assets/img/liuc.png"
             alt="">
      </div>
      <div class="mytipulBox">
        <span>
          <svg viewBox="0 0 16 16"
               class="bi bi-wallet-fill"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
            <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
          </svg>
        </span>
        <p>产品付款发布</p>
      </div>
      <div class="mytipulImg">
        <img src="../../assets/img/liuc.png"
             alt="">
      </div>
      <div class="mytipulBox">
        <span>
          <svg viewBox="0 0 16 16"
               class="bi bi-wallet-fill"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
            <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
          </svg>
        </span>
        <p>产品付款发布</p>
      </div>
      <div class="mytipulImg">
        <img src="../../assets/img/liuc.png"
             alt="">
      </div>
      <div class="mytipulBox">
        <span>
          <svg viewBox="0 0 16 16"
               class="bi bi-wallet-fill"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
            <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
          </svg>
        </span>
        <p>产品付款发布</p>
      </div>
      <div class="mytipulImg">
        <img src="../../assets/img/liuc.png"
             alt="">
      </div>
      <div class="mytipulBox">
        <span>
          <svg viewBox="0 0 16 16"
               class="bi bi-wallet-fill"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
            <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
          </svg>
        </span>
        <p>产品付款发布</p>
      </div>
    </div>
    <!-- 介绍 -->
    <div class="content">
      <h1>
        <span>卖家操作指南</span>
      </h1>
      <el-collapse v-model="activeName"
                   accordion>
        <el-collapse-item title="一致性 Consistency"
                          name="1">
          <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
        </el-collapse-item>
        <el-collapse-item title="反馈 Feedback"
                          name="2">
          <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
          <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
        </el-collapse-item>
        <el-collapse-item title="效率 Efficiency"
                          name="3">
          <div>简化流程：设计简洁直观的操作流程；</div>
          <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
          <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
        </el-collapse-item>
        <el-collapse-item title="可控 Controllability"
                          name="4">
          <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
          <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      workList: [
        {
          path: 'M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zM1 4v2h6V4H1zm8 0v2h6V4H9zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5V7zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5H7z',
          title: 'Sellers release preferential products',
          des: 'Amazon sellers can post free product information and coupons to buy a large number of products, most of which are discounted by more than 30%.'
        },
        {
          path: 'M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z',
          title: 'Release products and get discounts',
          des: 'Our community has released the best deals from sellers, and buyers can get them from crediscounts.com'
        },
        {
          path: 'M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.067 6.067 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.117 8.117 0 0 1-3.078.132 3.658 3.658 0 0 1-.563-.135 1.382 1.382 0 0 1-.465-.247.714.714 0 0 1-.204-.288.622.622 0 0 1 .004-.443c.095-.245.316-.38.461-.452.393-.197.625-.453.867-.826.094-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.2-.925 1.746-.896.126.007.243.025.348.048.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.175-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04zM4.705 11.912a1.23 1.23 0 0 0-.419-.1c-.247-.013-.574.05-.88.479a11.01 11.01 0 0 0-.5.777l-.104.177c-.107.181-.213.362-.32.528-.206.317-.438.61-.76.861a7.127 7.127 0 0 0 2.657-.12c.559-.139.843-.569.993-1.06a3.121 3.121 0 0 0 .126-.75l-.793-.792zm1.44.026c.12-.04.277-.1.458-.183a5.068 5.068 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.591 1.927-5.566 4.66-7.302 6.792-.442.543-.796 1.243-1.042 1.826a11.507 11.507 0 0 0-.276.721l.575.575zm-4.973 3.04l.007-.005a.031.031 0 0 1-.007.004zm3.582-3.043l.002.001h-.002z',
          title: 'Leave comments, share offers, and recommend products',
          des: 'Leave a positive comment and share it with the buyer so that more friends can enjoy the preferential products with you.'
        }
      ],
      activeName: '1'
    }
  }
}
</script>

<style lang="less" scoped>
.work {
  padding: 15px 0;
  .mytipul {
    padding: 15px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mytipulBox {
      width: 120px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #FFE2C5;
        svg {
          width: 35px;
          height: 35px;
          color: #FF992A;
        }
      }
      p {
        width: 100%;
        font-size: 20px;
        margin-top: 10px;
        line-height: 30px;
      }
    }
    .mytipulImg {
      padding-bottom: 40px;
    }
  }

  .content {
    width: 80%;
    background-color: white;
    margin: 15px auto;
    padding: 15px 0;
    h1 {
      position: relative;
      margin-bottom: 20px;
      &::after {
        content: "";
        position: absolute;
        width: 30%;
        height: 1px;
        background-color: #ff9933;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      span {
        position: relative;
        z-index: 1;
        background-color: white;
        font-weight: normal;
        font-size: 28px;
        color: #595757;
        padding: 0 20px;
      }
    }
    .el-collapse {
      padding: 0 20px;
    }
    /deep/.el-collapse-item__header {
      position: relative;
      padding-left: 20px;
      font-size: 16px;
      font-weight: 700;
      color: black;
    }
    /deep/.el-collapse-item__arrow {
      position: absolute;
      left: 0;
    }
  }
}
</style>
